import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

const lazyImport = (path) => lazy(() => import(`src/views/pages/${path}`));

const createRoute = ({
  exact = true,
  path,
  layout = null,
  guard = false,
  componentPath,
}) => ({
  exact,
  path,
  layout,
  guard,
  component: lazyImport(componentPath),
});

export const routes = [
  createRoute({ path: "/", layout: LoginLayout, componentPath: "Auth/Login" }),
  createRoute({
    path: "/login",
    layout: LoginLayout,
    componentPath: "Auth/Login",
  }),
  createRoute({
    path: "/forget",
    layout: LoginLayout,
    componentPath: "Auth/Forget",
  }),
  createRoute({
    path: "/reset-password",
    layout: LoginLayout,
    componentPath: "Auth/ResetPassword",
  }),
  createRoute({
    path: "/verify-otp",
    layout: LoginLayout,
    componentPath: "Auth/Verifyotp",
  }),
  createRoute({
    path: "/change-password",
    layout: DashboardLayout,
    componentPath: "Auth/ChangePassword",
  }),
  createRoute({
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/DashdoardHome",
  }),
  createRoute({
    path: "/graph",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Graph/Graph",
  }),
  createRoute({
    path: "/add-price",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/price/index",
  }),
  createRoute({
    path: "/view-price",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/price/index",
  }),
  createRoute({
    path: "/wallets-balance",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/Wallets/WalletBalance",
  }),
  createRoute({
    path: "/wallets-list",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/Wallets/Walletstable",
  }),
  createRoute({
    path: "/user-wallet",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/Wallets/UserWalletDetails",
  }),

  // Skills and Referral
  createRoute({
    path: "/skills-list",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/skills/index",
  }),
  createRoute({
    path: "/referral-list",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/referral/index",
  }),
  createRoute({
    path: "/referral-userlist",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/referral/UserList.js",
  }),

  // Marketplace
  createRoute({
    path: "/marketplace",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/CourseCreation/Index",
  }),
  createRoute({
    path: "/marketplace-list",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/MarketplaceList/index",
  }),

  createRoute({
    path: "/drop",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/CourseCreation/Index",
  }),
  createRoute({
    path: "/skill-drops-list",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/SkillDropList/index",
  }),

  createRoute({
    path: "/user-lists",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/Course&DropList/UserTable",
  }),
  createRoute({
    path: "/user-skilldrops-lists",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/Course&DropList/UserTable",
  }),

  createRoute({
    path: "/query-list",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/querylist/QueryList",
  }),
  createRoute({
    path: "/query-reply",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/querylist/Index",
  }),

  createRoute({
    path: "/notifications",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/announcement",
  }),
  createRoute({
    path: "/view-notification",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/announcement/UserListTable.js",
  }),

  createRoute({
    path: "/blog",
    guard: true,
    layout: DashboardLayout,
    componentPath: "Dashboard/Blogs/Blogs",
  }),

  createRoute({
    path: "/user-list",
    guard: true,
    componentPath: "Dashboard/userlist/UserList",
    layout: DashboardLayout,
  }),
  createRoute({
    path: "/view-user",
    guard: true,
    componentPath: "Dashboard/userlist/UserTabs",
    layout: DashboardLayout,
  }),
  createRoute({
    path: "/create-badges",
    guard: true,
    componentPath: "Dashboard/automation/Badge",
    layout: DashboardLayout,
  }),
  createRoute({
    path: "/badges-list",
    guard: true,
    componentPath: "Dashboard/automation/BadgeMain",
    layout: DashboardLayout,
  }),
  createRoute({
    path: "/member-ship",
    componentPath: "Dashboard/MembershipPlan/Index.js",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/subscribe-user",
    componentPath: "Dashboard/SubscribeUser/index",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/educator-request",
    componentPath: "Dashboard/EducatorRequest/CourseRequest",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/static",
    componentPath: "Dashboard/Static/Index.js",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/transaction-list",
    componentPath: "Dashboard/transaction/TransactionMangement",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/coe-partners",
    componentPath: "Dashboard/coepartners/Index.js",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/coe-request",
    componentPath: "Dashboard/CoeRequest/CoeRequest",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/subadmin-list",
    componentPath: "Dashboard/subadmin/SubAdmin",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/view-subadmin",
    componentPath: "Dashboard/subadmin/ViewParticularSubAdmin",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/tier-plan-transaction",
    componentPath: "Dashboard/MembershipPlan/SubscriptionTransaction",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/course-category",
    componentPath: "Dashboard/courseCategory/index",
    layout: DashboardLayout,
    guard: true,
  }),
  createRoute({
    path: "/course-category/subcategory",
    componentPath: "Dashboard/courseCategory/courseSubCategory/index",
    layout: DashboardLayout,
    guard: true,
  }),
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
