import { Contract } from "@ethersproject/contracts";
import { RPC_URL } from "src/constants";
import Web3 from "web3";
import axios from "axios";
import moment from "moment";

export const fetchFilterList = async (
  apiUrl,
  pagination,
  selectFilter,
  setFilterData,
  method,
  data,
  additionalParams = {}
) => {
  try {
    const params = {
      page: pagination?.page,
      limit: pagination?.total,
      search: selectFilter?.search || undefined,
      fromDate: selectFilter?.fromDate
        ? moment(selectFilter.fromDate).format("YYYY-MM-DD")
        : undefined,
      toDate: selectFilter?.toDate
        ? moment(selectFilter.toDate).format("YYYY-MM-DD")
        : undefined,
      ...additionalParams,
    };
    const cleanParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value != null && value !== ""
      )
    );
    // Send the request
    const config = {
      method: method ? "POST" : "GET",
      url: apiUrl,
      headers: {
        token: sessionStorage.getItem("indicDigitalAdmin"),
      },
    };

    if (data) {
      config.data = cleanParams;
    } else {
      config.params = cleanParams;
    }
    const res = await axios(config);

    // Check the response
    if (res.data.responseCode === 200) {
      setFilterData(res.data.result.docs);
    } else {
      setFilterData([]);
    }
  } catch (error) {
    console.error(error);
    setFilterData([]);
  }
};

const containsHtmlTags = (str) =>
  /<\/?[a-zA-Z]+(?:\s+[a-zA-Z-]+(?:="[^"]*")?)*\s*>/.test(str);

const hasConsecutiveRepeatedChars = (str) => /(\w)\1{3,}/.test(str);

export const validateString = (
  str,
  fieldName,
  minLength,
  maxLength,
  allowedCharsRegex
) => {
  if (containsHtmlTags(str)) {
    return `${fieldName} contains invalid input.`;
  }
  if (!str.trim()) {
    return `${fieldName} is required.`;
  }
  if (!/^[A-Za-z]/.test(str)) {
    return `${fieldName} must start with a letter.`;
  }
  if (!allowedCharsRegex.test(str)) {
    return `${fieldName} contains invalid characters.`;
  }
  if (str.length < minLength) {
    return `${fieldName} must be at least ${minLength} characters long.`;
  }
  if (str.length > maxLength) {
    return `${fieldName} cannot exceed ${maxLength} characters.`;
  }
  if (hasConsecutiveRepeatedChars(str)) {
    return `${fieldName} cannot have the same character repeated four times consecutively.`;
  }
  return "";
};

export const validateImageSize = (image, maxSize) => {
  if (!image) {
    return "Image is required.";
  }
  if (image.size > maxSize) {
    return `Image size exceeds ${maxSize / 1000000}MB limit.`;
  }
  return "";
};

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}
export function sortTest(add) {
  const sortAdd = `${add.slice(0, 10)}...`;
  return sortAdd;
}

export function sortName(str1) {
  let split_names = str1.trim().split(" ");
  if (split_names.length > 1) {
    return split_names[0] + " " + split_names[1].charAt(0) + ".";
  }
  return split_names[0];
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export const getWeb3Provider = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  return httpProvider;
};

export const getWeb3Obj = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress) => {
  const web3 = getWeb3Obj();
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export function generateUniqueAvatar(value) {
  return `https://avatars.dicebear.com/api/identicon/${value}.svg`;
}

export function validateAccountAddress(account) {
  try {
    const web3 = getWeb3Obj();
    const accountCheck = web3.utils.toChecksumAddress(account);
    if (accountCheck !== "") {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / ((1000 / 60) * 60 * 24)),
        hours: Math.floor((difference / ((1000 / 60) * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
export const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  elevation: 0,
  PaperProps: {
    style: {
      maxHeight: 100,
    },
  },
};
