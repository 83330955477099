import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
} from "@material-ui/core";

import { Menu as MenuIcon } from "react-feather";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";
const useStyles = makeStyles((theme) => ({
  mainTopBox1: {
    "& .MuiPopover-paper": {
      top: "61px !important",
    },
  },
  mainTopBox: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  toolbar: {
    padding: "10px 30px 7px 30px",
    boxShadow: "0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 0px 10px",
    },
    "& .MuiMenuItem-root": {
      minHeight: "34px",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& svg": {
      color: theme.palette.text.primary,
    },
    "& .leftBox": {
      width: "306px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainTopBox}>
      <AppBar
        elevation={0}
        className={clsx(classes.root, className)}
        color="inherit"
        style={{ background: "#615dfa" }}
      >
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              color="#00e0b0"
              onClick={onMobileNavOpen}
              style={{ padding: "0px" }}
            >
              <SvgIcon>
                <MenuIcon style={{ color: "#ffffff", fontSize: "25px" }} />
              </SvgIcon>
            </IconButton>
          </Hidden>
          &nbsp; &nbsp;
          <Box className={classes.mainheader}>
            <Grid container alignItems="center">
              <Grid item lg={3} md={3} sm={4} xs={6}>
                <Box className="leftBox">
                  <Logo width="125" />
                </Box>
              </Grid>
              <Hidden xsDown>
                <Grid lg={4} md={6} sm={5}></Grid>
              </Hidden>
              <Grid lg={5} md={3} sm={3} xs={6}>
                <Box className="displayEnd">
                  <Box className="themeButton"></Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();

  const auth = useContext(AuthContext);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Hidden xsDown>
        <Box>
          <Typography variant="h5">NFT Marketplace</Typography>
          <Typography variant="body1" style={{ color: "#ffffff9c" }}>
            example@gmail.com
          </Typography>
        </Box>
      </Hidden>
      &nbsp; &nbsp;
      <Avatar
        src={
          auth?.userData?.profilePic
            ? `${auth?.userData?.profilePic}`
            : "https://picsum.photos/533/357"
        }
        className={classes.avatar}
      />
    </Box>
  );
}
