import React from "react";
import {
 
  Typography,
  makeStyles,
  Box,
  
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
  loginLayoutBox: {
    background: theme.palette.background.white,
    display: "flex",
    zIndex: "9",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    "& .loginLayoutBoxImg": {
      width: "55%",
      zIndex: "1",
      position: "fixed",
      backgroundSize: "cover",
      backgroundImage: "url(./images/banner_login.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100vh",
      left: 0,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& h1": {
        fontSize: "65px",
      },
    },
  },
  loginContentLayoutBox: {
    background: theme.palette.background.white,
    position: "absolute",
    right: 0,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "80px 0 50px",
    },
  },
}));

export default function LoginLayout({ children }) {
 
  const classes = useStyles();

  return (
 
      <Box className={classes.loginLayoutBox}>
        <Box className="loginLayoutBoxImg">
          <Box align="center">
            <Typography variant="h4" style={{ color: "#fff" }}>
              Welcome to
            </Typography>
            <Box mt={1} mb={2}>
              <RouterLink to="/" style={{ textDecoration: "none" }}>
                <Typography variant="h1" style={{ color: "#fff" }}>
                  Gyan Batua
                </Typography>
              </RouterLink>
            </Box>

            <Typography variant="h3" style={{ color: "#fff" }}>
              {" "}
              Dashboard
            </Typography>
          </Box>
        </Box>
        <Box className={classes.loginContentLayoutBox}>
          <Box style={{ width: "100%" }}>{children}</Box>
        </Box>
     
      </Box>

  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
