import React, { createContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const defaultSettings = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: "LIGHT",
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.log("err", err);
  }

  return settings;
};

export const storeSettings = (settings) => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {},
});

const getTandC = () => {
  return !!window.localStorage.getItem("tAndC");
};

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const [acceptTandC, setAcceptTandC] = useState(false);

  const handleSaveSettings = (update = {}) => {
    const mergedSettings = _.merge({}, currentSettings, update);
    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();
    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
    setAcceptTandC(getTandC());
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  const updateUserTandC = (data) => {
    window.localStorage.setItem("tAndC", true);
    setAcceptTandC(true);
  };

  const contextValue = useMemo(
    () => ({
      settings: currentSettings,
      saveSettings: handleSaveSettings,
      acceptTandC,
      updateUserTandC,
    }),
    [currentSettings, acceptTandC]
  );

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;

SettingsProvider.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.node.isRequired,
};
