
export const nftIndicchain = "0x6BF8c37eCce52d8EB0B410de92fef4dE9841d268";
export const NetworkContextName = "Indicchain";
export const ACTIVE_NETWORK = 1337;
export const RPC_URL = process.env.REACT_APP_FALLBACK_RPC_URL;
export const newHexaDecimal = "0x539";

export const NetworkDetails = [
  {
    chainId: newHexaDecimal,
    chainName: "Indicchain",
    nativeCurrency: {
      name: "GYANCOIN",
      symbol: "GC",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://explorer.gyanbatua.com/"],
  },
];

export const getNetworkDetails = (chianId) => {
  switch (chianId?.toString()) {
    case ACTIVE_NETWORK.toString():
      return [
        {
          chainId: newHexaDecimal,
          chainName: "Indicchain",
          nativeCurrency: {
            name: "GyanToken",
            symbol: "GC",
            decimals: 18,
          },
          rpcUrls: [RPC_URL],
          blockExplorerUrls: ["https://explorer.gyanbatua.com/"],
        },
      ];
    case ACTIVE_NETWORK:
      return [
        {
          chainId: newHexaDecimal,
          chainName: "Indicchain",
          nativeCurrency: {
            name: "Indicchain",
            symbol: "GC",
            decimals: 18,
          },
          rpcUrls: [RPC_URL], //testnet
          blockExplorerUrls: ["https://explorer.gyanbatua.com/"],
        },
      ];
  }
};


