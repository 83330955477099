import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PropTypes from "prop-types";
import { PiGitPullRequestBold } from "react-icons/pi";
import { SlGraph } from "react-icons/sl";

import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import { AiFillDashboard } from "react-icons/ai";
import { BsFillPersonPlusFill } from "react-icons/bs";
import NavItem from "./NavItem";
import { TfiAnnouncement } from "react-icons/tfi";
import { FaBlog } from "react-icons/fa";
import {
  MdHistory,
  MdCategory,
  MdCardMembership,
  MdSubscriptions,
} from "react-icons/md";
import { AuthContext } from "src/context/Auth";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CardMembershipTwoToneIcon from "@material-ui/icons/CardMembershipTwoTone";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item?.title && item?.title + depth;
  if (item?.items && item?.check) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        check={item?.check || false}
        subItems={item?.subItems || []}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item?.items || [],
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item?.href}
        icon={item?.icon}
        info={item?.info}
        check={item?.check}
        key={key}
        title={item?.title}
        subItems={item?.subItems}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    top: "76px",
    width: "270px",
    height: "calc(100% - 115px)",
    margin: "5px 10px 10px 15px",
    background: "#fff",
    boxShadow: "0 0 40px 0 rgba(94,92,154, .06)",
    marginTop: "21px",
    marginLeft: "13px",
    borderRadius: "none",
    borderRight: "none",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "16px",
    background: "transparent",
    fontWeight: "700",
    fontSize: "13px",
    color: "#3e3f5e",
    width: "90%",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  footerBox: {
    width: "100%",
    height: "80px",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "-4px",
    overflow: "hidden",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const userType = auth?.userData?.userType;

  const history = useHistory();

  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const sections = [
    {
      items: [
        {
          title: "Dashboard",
          icon: AiFillDashboard,
          href: "/dashboard",
          check: auth?.userData?.permissions?.dashboard,
        },
        {
          title: "Users List",
          icon: BsFillPersonPlusFill,
          href: "/user-list",
          check: auth?.userData?.permissions?.userManagement,
        },
        {
          title: "Badge",
          icon: LoyaltyIcon,
          href: "/badges-list",
          check: auth?.userData?.permissions?.badges,
        },
        {
          title: "Graph",
          icon: SlGraph,
          href: "/graph",
          check: auth?.userData?.permissions?.userManagement,
        },
        {
          title: "Wallet",
          icon: BsFillPersonPlusFill,
          href: "/wallets-list",
          check: auth?.userData?.permissions?.userManagement,
          subItems: [
            {
              title: "Users Wallet",
              href: "/wallets-list",
              check: auth?.userData?.permissions?.userManagement,
            },
            {
              title: "Gc Rewards",
              href: "/transaction-list",
              check: auth?.userData?.permissions?.userManagement,
            },
            {
              title: "Wallet Balance",
              href: "/wallets-balance",
              check: auth?.userData?.permissions?.userManagement,
            },
          ],
        },

        {
          title: "Skills Marketplace",
          icon: MdHistory,
          href: "/marketplace",
          check: auth?.userData?.permissions?.courses,
          subItems: [
            {
              title: "Skills Marketplace",
              icon: SupervisorAccountIcon,
              href: "/marketplace",
              check: auth?.userData?.permissions?.dropCourses,
            },
            {
              title: "Marketplace List",
              icon: MdHistory,
              href: "/marketplace-list",
              check: auth?.userData?.permissions?.marketplaceList,
            },
          ],
        },

        {
          title: "Drops",
          icon: MdHistory,
          href: "/drop",
          check: auth?.userData?.permissions?.dropCourses,
          subItems: [
            {
              title: "Skill Drops",
              icon: SupervisorAccountIcon,
              href: "/drop",
              check: auth?.userData?.permissions?.dropCourses,
            },
            {
              title: "Skill Drops List",
              icon: MdHistory,
              href: "/skill-drops-list",
              check: auth?.userData?.permissions?.skillDropsList,
            },
          ],
        },

        {
          title: "CoE Partners",
          icon: SupervisorAccountIcon,
          href: "/coe-partners",
          check: auth?.userData?.permissions?.COEPartners,

          subItems: [
            {
              title: "CoE Partners",

              href: "/coe-partners",
              check: auth?.userData?.permissions?.COEPartners,
            },
            {
              title: "CoE Partner Req",
              href: "/coe-request",
              check: auth?.userData?.permissions?.COEPartners,
            },
          ],
        },
        {
          title: "Tier Plan Transaction",
          icon: CardMembershipTwoToneIcon,
          href: "/tier-plan-transaction",
          check: auth?.userData?.permissions?.tierPlanSubscribe,
        },
        {
          title: "Skill Drops Approval",
          icon: PiGitPullRequestBold,
          href: "/educator-request",
          check: auth?.userData?.permissions?.badges,
        },
        {
          title: "Membership Plans",
          icon: MdCardMembership,
          href: "/member-ship",
          check: auth?.userData?.permissions?.membershipPlan,
        },
        {
          title: "Category List",
          icon: MdCategory,
          href: "/course-category",
          check: auth?.userData?.permissions?.category,
        },

        {
          title: "Subadmin List",
          icon: SupervisorAccountIcon,
          href: "/subadmin-list",
          check: auth?.userData?.permissions?.Request,
        },

        {
          title: "Ticket Query Request List",
          icon: QueryBuilderIcon,
          href: "/query-list",
          check: auth?.userData?.permissions?.queryrequest,
        },
        {
          title: "Notifications",
          icon: TfiAnnouncement,
          href: "/notifications",
          check: auth?.userData?.permissions?.announcement,
        },
        {
          title: "Newsletter Subscribers",
          icon: MdSubscriptions,
          href: "/subscribe-user",
          check: auth?.userData?.permissions?.newsletterSubscribers,
        },

        {
          title: "Create Blogs",
          icon: FaBlog,
          href: "/blog",
          check: auth?.userData?.permissions?.createBlogs,
        },

        {
          title: "Upload Static Content",
          icon: FaBlog,
          href: "/static",
          check: auth?.userData?.permissions?.staticContentUpload,
        },
      ],
    },
  ];

  const content = (
    <Box height="100%" display="flex" flexDirection="column" pt={1}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box
            className="sideMenuBox"
            style={{ scrollbarWidth: "thin !important" }}
          >
            {sections.map((section, i) => (
              <List
                key={`menu-${"item-" + i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section?.subheader ?? ""}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section?.img ?? null,
                  items:
                    section?.items.map((item) => ({
                      ...item,
                      check: userType === "SUB_ADMIN" ? item.check : true,
                      subItems: item?.subItems ?? null,
                    })) ?? [],
                  pathname: location?.pathname ?? "",
                })}
              </List>
            ))}
          </Box>
        </Box>
        <Box className={classes.footerBox}>
          <Button
            onClick={() => setIsLogout(true)}
            className={classes.logoutButton}
          >
            <ExitToAppIcon
              style={{
                marginRight: "16px",
              }}
            />
            &nbsp; Logout
          </Button>
        </Box>
        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography variant="h6" color="primary">
                  Are you sure want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="primary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    history.push("/");
                    sessionStorage.clear();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
